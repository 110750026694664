



* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	position: relative;
}




:root {
	--padding-hor: 1rem;
	--padding-ver: 1rem;
	
	--margin-hor: 1rem;
	--margin-ver: 1rem;
	
	--border-radius: .2rem;
	--border-thick: .2rem;
	
	--color-bg-cell: #eeeeee;
	--color-bg-beforeweek: #e0e0e0;
	--color-bg-cellspacing: #fff;
	--color-bg-celldividers: #e0e0e0;
	--color-bg-celldividers-thisweek: #bf4040;
	--color-bg-cellselected: #5e5e5e;
	--color-border-cellselected: #c3c3c3;
	--color-txt-cellselected: #fff;
	--color-txt: #3b3b3b;
	--color-txt-grey: #888;
	--color-txt-lightgrey: #bbb;
	--color-link-hover: #008bc9;
	
	--color-bg-taskgroups: #d1d1d1;
	
	--color-btn-bg: #64a645;
	--color-btn-edge: #4c7c37;
	--color-btn-text: #fff;
	--color-btn-bg-hover: #6eb350;
	--color-btn-bg-white-hover: #e0e0e0;
	
	--color-input-bg: #f3f3f3;
	--color-input-text: #008fce;
	--color-input-innershadow: rgba(0,0,0,.25);
	
	--color-ui-bg: #fff;
	
	--spacing-rows-half: .65rem;
	
	
	--font-accent-regular-family: "Klint LT W02 Regular", Helvetica, Arial, sans-serif;
	--font-accent-regular-weight: 400;
	--font-accent-bold-family: "Klint LT W02 Medium", Helvetica, Arial, sans-serif;
	--font-accent-bold-weight: 400;
	--font-size: 1rem;
	--lineheight: 1.25;

	--screenwidth: 1098px;
}


html {
  font-size: 100%;
}

html.android {
	font-size: 70%;
}

body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;
	font-size: var(--font-size);
	line-height: var(--lineheight);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}


a {
	color: var(--color-txt);
	text-decoration: none;
}
a:hover {
	color: var(--color-link-hover);
}


.margin-top { margin-top: var(--margin-ver); }
.margin-bottom { margin-bottom: var(--margin-ver); }
.margin-left { margin-left: var(--margin-hor); }
.margin-half-left { margin-left: calc(var(--margin-hor) / 2); }
.margin-half-top { margin-top: calc(var(--margin-ver) / 2); }
.margin-half-bottom { margin-bottom: calc(var(--margin-ver) / 2); }

.nomargin { margin: 0 !important; }

.padding-top { padding-top: var(--padding-ver); }
.padding-left { padding-left: var(--padding-ver); }
.padding-bottom { padding-bottom: var(--padding-ver); }




thead .filter-state > span,
thead .filter-state > button,
thead .filter-state > .resource,
table .tag,
.fnt-sm {
	font-weight: 400;
	font-size: .6em;
	line-height: var(--lineheight)
}
.txt-grey {
	color: var(--color-txt-grey);
}
.txt-lightgrey {
	color: var(--color-txt-lightgrey);
}



button,
input {
	display: block;
	width: 100%;
	height: 2.5rem;
	line-height: 2.5rem;
	padding: 0 .75rem;

	border-radius: var(--border-radius);

	border: 0;
	outline: 0;
	appearance: none;
}
button,
input,
tbody th h3.project {
	font-family: var(--font-accent-bold-family);
	font-weight: var(--font-accent-bold-weight);

	font-smoothing: antialiased;
}

button {
	border-right: 0;
	border-left: 0;
	border-bottom: var(--border-thick) solid var(--color-btn-edge);
	cursor: pointer;
	text-decoration: none;
	text-align: center;

	background-color: var(--color-btn-bg);
	color: var(--color-btn-text);
}
button.inline {
	display: inline-block;
	width: auto;
}
button:hover {
	background-color: var(--color-btn-bg-hover);
	text-decoration: none;
}
button:active {
	background-color: var(--color-btn-bg);
	border-bottom: calc(var(--border-thick)/3) solid var(--color-btn-edge);
	border-top: calc(var(--border-thick)/2) solid #fff;
	margin-top: calc(var(--border-thick)/2);
	vertical-align: bottom;
}

button:disabled {
	opacity: 0.5;
}

.btn-small {
	width: auto !important;
}
.no-ui {
	overflow: hidden;
	border: none;
	text-indent: -999em;
}
.no-ui:active {
	border: none;
	margin-top: none;
}


form { width: 100%; }
input {
	width: 100%;

	background: var(--color-input-bg);
	color: var(--color-input-text);
	box-shadow: 0 .1rem .3rem var(--color-input-innershadow) inset;

	border-radius: 0;
	-webkit-user-select: auto;
	-moz-user-select: auto;
	-ms-user-select: auto;
	user-select: auto;
}
input.inline {
	display: inline-block;
	width: auto;
}
input.align-right {
	text-align: right;
}

input[type="checkbox"].slider {
	height: 2em;
	margin: 4px 0;
	vertical-align: middle;
	background-image: none;

	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	
	overflow: visible;
	width: 45px;
	border: none;
	border-radius: 60px;
	
	cursor: pointer;
}
input[type="checkbox"].slider:before,
input[type="checkbox"].slider.edit:before {
	width: 100%;
	height: 100%;

	transition: background-color 0.2s;
	background-color: var(--color-bg-beforeweek);
	border-radius: 60px;
}
input[type="checkbox"].slider:after,
input[type="checkbox"].slider.edit:after,
input[type="checkbox"].slider:before,
input[type="checkbox"].slider.edit:before {
	content: '';
	display: block !important;
	position: absolute;
	left: 0;
	top: 0;
}
input[type="checkbox"].slider:after,
input[type="checkbox"].slider.edit:after {
	content: "\2016";
	font-size: 13px;
	line-height: 2em;
	text-align: center;
	
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: none;
	background-color: var(--color-ui-bg);
	border: 1px solid var(--color-bg-cellselected);

	transition: left 0.3s, color 0.2s;
	
	width: 60%;
	height: 84%;
	left: 6%;
	top: 8%;
	box-shadow: 4px 4px 6px rgba(0, 0, 0, .3);
	border-radius: 60px;
	border-color: #fff;
	color: var(--color-bg-cellselected);
}
input[type="checkbox"].slider:checked:after,
input[type="checkbox"].slider.edit:checked:after {
	left: 34%;	
	color: var(--color-btn-bg);
}
input[type="checkbox"].slider:checked:after,
input[type="checkbox"].slider.edit:checked:after,
input[type="checkbox"].slider:hover:after,
input[type="checkbox"].slider.edit:hover:after {
	color: var(--color-link-hover);
}
input[type="checkbox"].slider:checked:before {
	background-color: var(--color-btn-bg);
}
input[type="checkbox"].slider.mini:after,
input[type="checkbox"].slider.mini.edit:after {
	box-shadow: 2px 2px 4px rgba(0, 0, 0, .3)
}




.ui-popover-container {
	position: absolute;
	right: 120%;
	top: 50%;
	margin-top: -2.35rem;
	height: 4.7rem;
	background-color: var(--color-ui-bg);
	padding: var(--padding-ver) var(--padding-hor);
	white-space: nowrap;
	box-shadow: 0 0 2rem rgba(0,0,0,.2);
	z-index: 1000;
}
.ui-popover-container.taller-ui {
	margin-top: -3.15rem;
	height: 6.3em;
}
.ui-popover-container.auto-tall {
	height: auto;
}
.ui-popover-container.right-side {
	right: auto;
	left: 100%;
	margin-left: 1rem;
}
.ui-popover-container.bottom-right-side {
	right: auto;
	top: 100%;
	margin-top: 1rem;
	margin-left: -1rem;
}
.ui-popover-container > * {
	white-space: normal;	
}
.ui-popover-container > *:not(.help) {
	display: inline-block;
	vertical-align: middle;
	width: 8rem;
	margin-right: var(--padding-hor);
}
.ui-popover-container > button {
	color: var(--color-btn-text);
}
.ui-popover-container > .help {
	margin-top: calc(var(--margin-ver)/2);
}

.ui-popover-container:before,
.ui-popover-container:after {
	content: "";
	position: absolute;
	width: 0;
	height: 0;
	left: 100%;
	top: 50%;
	border-left: .5rem solid var(--color-ui-bg);
}
.ui-popover-container.right-side:before,
.ui-popover-container.right-side:after {
	left: auto;
	right: 100%;
	border-left: 0;
	border-right: .5rem solid var(--color-ui-bg);
}
.ui-popover-container.bottom-right-side:before,
.ui-popover-container.bottom-right-side:after {
	left: 1rem;
	top: -.5rem;
	margin-top: 0;
	border-left: .5rem solid transparent;
	border-bottom: .5rem solid var(--color-ui-bg);
	border-top: 0;
}
.ui-popover-container.bottom-right-side:after {
	margin-left: .5rem;
	border-left: 0;
	border-right: .5rem solid transparent;
}
.ui-popover-container:before {
	margin-top: 0rem;
	border-bottom: .5rem solid transparent;
}
.ui-popover-container:after {
	margin-top: -.5rem;
	border-top: .5rem solid transparent;
}


.ui-input {
	clear: right;
	float: right;
	z-index: 1;
}




table  {
	border-collapse: collapse;
	width: 100%;
}
tr {
	overflow-x: hidden;
}


/* logo */
header .logo {
	font: 1.5rem/2.6087rem var(--font-accent-bold-family);
}
header h2 {
	display: inline-block;
	text-indent: -999px;
	overflow: hidden;
	vertical-align: bottom;
}
header h2 {
	background: #000 url('img/logo-black-image.png') no-repeat center center;
}
header h2 {
	width: 3.0109rem;
	height: 2.6087rem;
	background-size: 2.373917rem;
	border-right: .27156567rem solid #f12b3b;
	margin: 0 .25rem 0 0;
	padding: 0;
}
header a:first-child:hover {
	color: var(--color-txt);
}


header a:not(:last-child) {
	margin-right: 1rem;
	vertical-align: bottom;
}


.tableFixHead {
	overflow: scroll;
}


h3 {
	white-space: normal;
	line-height: 1.25
}




tbody th,
tbody td {
	background-color: var(--color-bg-cell);
	padding: 0;
}

#header-logo,
#header-weeks {
	background: var(--color-bg-cell);
	position: -webkit-sticky;
	position: sticky;
	/*position: fixed;*/
	top: 0;
	white-space: nowrap;
}
#header-projects {
	position: -webkit-sticky;
	position: sticky;
	/*position: fixed;*/
	left: 0;
}
#header-projects tr:not(.spacer) th,
#header-projects tr:last-child th {
	padding: var(--padding-ver) var(--padding-hor);
}
#tablecontent > thead > tr > th {
	padding: 0;
}
tbody th > div {
	width: 100%;
}
tbody th > div > * {
	max-width: 100%;
	padding: 0;
	margin: 0;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: inline-block;
}
tbody th > div > button,
tbody th > div > button:hover {
	color: var(--color-txt);
	background-color: transparent;
	border: none;
	border-radius: 0;
	padding: 0;
	margin: 0;
	text-align: left;
	height: auto;
}
tbody th > div > button:hover {
	color: var(--color-link-hover);
}
tbody th > div > button:active {
	border: none;
	margin: 0;
}

tbody th h3.project {
	padding: 0;
	margin: 0;
	cursor: pointer;
	color: var(--color-txt);
}

#header-logo {
	left: 0;
	padding: var(--padding-ver) var(--padding-hor);
	background-color: var(--color-bg-cellspacing);
	vertical-align: middle;
}
thead th:first-child,
tbody th {
	text-align: left;
}
#header-projects > table {
	z-index: 1;
	width: 18vw;
	min-width: 14rem;
	max-width: 22rem;
}
#header-projects > *,
#content-projects > * {
	display: table;
	width: auto;
}
header,
#content-projects > * {
	width: 100%;
}
#header-projects > thead,
#content-projects > thead {
	z-index: 1000;
}
tbody th,
tbody td {
	vertical-align: top;
}


.taskgroup-shelf,
.project-taskgroup-shelf {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.taskgroup-shelf:hover:after,
.taskgroup-shelf.unfolded:after {
	content: "";
	display: inline-block;
	width: 1.3rem;
	height: 1rem;
	margin-right: -1.3rem;
	background: transparent url('img/icon-arrow-down-small-blue.svg') no-repeat center right;
	background-size: 1rem;
	vertical-align: text-top;
}
.taskgroup-shelf.unfolded:after {
	background-image: url('img/icon-arrow-up-small-blue.svg');
}

.taskgroup-shelf .icon {
	display: inline-block;
	background: transparent url('img/icon-drawer-closed-grey.svg') no-repeat top center;
	background-size: 1.25rem;
	width: 1.25rem;
	height: 1.25rem;
	vertical-align: middle;
	margin-right: calc(var(--margin-hor) / 3);
}
.taskgroup-shelf.unfolded .icon {
	background-image: url('img/icon-drawer-open-grey.svg');
}
.project-taskgroup-shelf {
	background-color: var(--color-bg-cell);
	width: 20rem;
	left: 0;
	margin-top: 2rem;
	z-index: 10;
	position: absolute;
	padding: var(--padding-ver);
	border-radius: var(--border-radius);
	box-shadow: 0 3px 15px rgba(0,0,0,.2);
	
	transition: opacity 300ms, transform 300ms;
	opacity: 1;
	transform: translate3d(0, 0, 0);
}
.folded + .project-taskgroup-shelf {
	opacity: 0;
	transform: translate3d(0, 5px, 10px);
	
	pointer-events: none;
}
.project-taskgroup-shelf > * {
	left: 0 !important;
	top: 0 !important;
	position: relative !important;
}
.project-taskgroup-shelf .taskgroup-container:not(:first-child) {
	margin-top: calc(var(--margin-ver) / 2);
}
.project-taskgroup-shelf .project-bg:not(:first-child):after,
.project-taskgroup-shelf .trello-container {
	display: none;
}
.project-taskgroup-shelf .taskgroup-container,
.project-taskgroup-shelf .bg-container-wrapper {
	min-width: 100%;
	max-width: 100%;
	overflow: hidden;
}

.header-dropzone.dragover-tag:after,
.header-dropzone.dragover-taskgroup:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: transparent url('img/icon-drawer-open-grey.svg') no-repeat center center;
	background-size: 2rem;
	pointer-events: none;
	box-shadow: inset 0 0 60px rgba(0,139,201,.2);
	z-index: 1;
}
.header-dropzone.dragover-tag:after {
	background-image: url('img/icon-tag-grey.svg');
}

/* layering for sticky headers */
#resource-panel { z-index: 2000; }
#tablemain:before { z-index: 1006; }
#header-logo { z-index: 1005; }
#header-weeks { z-index: 1002; }
#tag-panel { z-index: 1001; }
#header-projects { z-index: 1004; }
tbody td { z-index: 0; }
thead th:first-child:after { z-index: 10; }


/* Shadow */
#header-projects:after,
.project-bg:after,
.week-bg:after,
.empty-bg:after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 100%;
	width: .7rem;
	background: linear-gradient(to right, rgba(0,0,0,.2) 0%, rgba(0,0,0,.1) 25%, rgba(0,0,0,.05) 50%, rgba(0,0,0,0) 100%);
}
#header-projects:after {
	top: -6rem;
}
thead th:not(:first-child):after,
.project-bg:after,
.week-bg:after,
.empty-bg:after {
	width: 1px;
	left: 0;
	background: var(--color-bg-celldividers);
}
thead th.this-week:not(:first-child):after,
.project-bg.this-week:after,
.week-bg.this-week:after,
.empty-bg.this-week:after {
	width: 2px;
	left: -1px;
}
.before-week {
	background-color: var(--color-bg-beforeweek);
}
.empty-bg.before-week {
	background-color: var(--color-bg-cell);
}
.taskgroup-container .before-week {
	background-color: var(--color-border-cellselected);
}
.before-week:after,
.compactable .project-bg:after {
	background: rgba(0,0,0,.1);
}
.this-week {
	color: var(--color-bg-celldividers-thisweek);
}
.this-week:after {
	background-color: var(--color-bg-celldividers-thisweek);
}
.compactable .this-week:after {
	background-color: var(--color-bg-celldividers-thisweek);
}
.conflict-warning:before {
	content: "\26A0";
	position: absolute;
	left: 0;
	right: 0;
	top: var(--padding-ver);
	bottom: auto;
	text-align: center;
	line-height: 1;
	color: var(--color-bg-celldividers-thisweek);
}

.compactable .btn-compact,
.compactable .btn-compact:hover {
	display: inline-block;
	width: auto;
	background: transparent;
	padding-right: 3rem;
	line-height: 1.25;
	vertical-align: baseline !important;
}
.compactable .btn-compact:hover:after {
	content: "";
	display: inline-block;
	width: 1.3rem;
	height: 1rem;
	margin-right: -1.3rem;
	background: transparent url('img/icon-arrow-up-small-blue.svg') no-repeat center right;
	background-size: 1rem;
}
.compactable .btn-compact.is-compacted:hover:after {
	background-image: url('img/icon-arrow-down-small-blue.svg');
}
.compactable .btn-compact h3 {
	display: inline;
}

thead th:before {
	top: auto;
	left: 0;
	right: 0;
	height: 1px;
	width: auto;
	background: var(--color-bg-cellspacing);
}

.bg-container {
	padding: 0;
	margin: 0;
	white-space: nowrap;
	height: 100%;
}

.project-bg,
.week-bg,
.empty-bg {
	float: left;
	width: 6rem;
	min-width: 6rem;
}
.project-bg {
	height: 100%;	
}
#tablemain > tbody > tr > th:first-child,
.week-bg {
	height: 6rem;
	min-height: 6rem;
}
.week-bg {
	line-height: 6rem;
	text-align: center;
}
.empty-bg {
	height: 100%;
}
.week-newmonth {
	font-weight: normal;
	font-size: .6em;
	text-transform: uppercase;
	color: var(--color-txt-lightgrey);
	position: absolute;
	top: calc(1.5 * var(--padding-ver) );
	bottom: 0;
	width: 100%;
	text-align: center;
}

tbody tr:not(.spacer) td:hover {
	z-index: 0 !important;
}
/*
tbody tr:not(.spacer) td:hover:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	border: var(--border-thick) solid var(--color-border-cellselected);
	z-index: 1;
	
	width: 200%;
}
*/


/* spacer */
tbody tr.spacer th,
tbody tr.spacer td {
	padding-top: 0;
	padding-bottom: 0;
	background-color: var(--color-bg-cellspacing);
}
tbody tr.spacer td {
	padding: 0;
}
tbody tr.spacer .bg-container {
	height: calc(var(--margin-ver) / 2);
}
tbody tr.spacer:last-child .bg-container {
	height: 100%;
}
#content-projects tr:not(.spacer),
#header-projects tr:not(.spacer) {
	height: 3.75rem;
}
#header-projects .bg-container {
	display: block;
}


#content-projects tbody tr:last-child td,
#header-projects thead tr:last-child th {
	display: block;
	min-height: 140px;
}


/* projects */
.project {
	cursor: grab;
}
#tag-panel form:not(.no-button),
table form:not(.no-button) {
    padding-right: 2.5em;
}
#tag-panel form.ok-button,
table form.ok-button {
    padding-right: 3.25em;
}
#tag-panel form input[type=text],
table form input[type=text] {
	width: 100% !important;
}
#tag-panel form button,
table form button {
	position: absolute;
	right: 0;
}


/* markers */
.taskgroup-container {
	position: absolute;
	left: 0;
	z-index: 2;
}
.taskgroup .title {
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	
	font-size: calc(var(--font-size) / 1.25);
	line-height: calc(var(--line-height) / 1.25);
	
	padding-left: calc(var(--padding-hor)/4);
	padding-right: 0;
	
	z-index: 10;
	
	transition: margin-left .9s ease-in-out;
}
.taskgroup form.title {
	overflow: visible;
	position: absolute;
	z-index: 1;
}
.presentation-state .taskgroup .trello-container.invalid + .title,
.touch-disabled .taskgroup .trello-container.invalid + .title {
	padding-right: calc(var(--padding-hor)/2);
}
.taskgroup form.title {
    padding-right: calc(var(--padding-hor)/4);
    padding-left: calc(var(--padding-hor)/4);
}
.taskgroup.compacted .title {
	pointer-events: none;
	position: absolute;
	top: 50%;
	width: 100%;
	margin-top: -.8rem;
}
.taskgroup .title input[type=text] {
	text-transform: none;
    line-height: 2;
    height: auto;
	padding-left: calc(var(--padding-hor)/2 + .75rem);
	padding-right: calc(var(--padding-hor)/2);
    margin: 0;
    text-align: left;
}
.taskgroup-container .ui-popover-container {
	margin-top: -2.4rem;
}
.taskgroup-container .ui-popover-container.right-side {
	margin-left: calc(var(--padding-hor)/2);
}
.taskgroup-container .bg-container-wrapper {
	position: absolute;
	top: 0;
	bottom: 0;
	overflow: hidden;
	background-color: var(--color-bg-taskgroups);
	z-index: 0;
}
.taskgroup-container .tasks-container {
	position: absolute;
	z-index: 2;
}
.taskgroup-container .project-bg:first-child:after {
	width: calc(var(--padding-hor)/4);
}

.task-container {
	position: absolute;
	left: 0;
	z-index: 2;
	
	padding-left: calc(var(--padding-hor)/2);
	padding-right: calc(var(--padding-hor)/2);
	overflow: hidden;
}
.task-container > div {
	overflow: hidden;
}
.task-container .label {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 2;
}
.task-container .conflicts {
	display: block;
	width: 100%;
	height: 100%;
	z-index: 1;
}
.task-container .conflict {
	background: transparent url('img/bg-warn-stripes.png') no-repeat center center;
	background-size: 100%;
	background-size: cover;
	background-position: center right;
	position: absolute;
	top: 0;
	bottom: 0;
}

.taskgroup-container.is-dragging,
.task-container.is-dragging {
	opacity: .2 !important;
}
.taskgroup,
.task {
	width: 100%;
}
.task-container .label,
.task-container .conflicts,
.drag-task .task-container,
.drag-taskgroup .taskgroup-container:not(.is-dragging),
.drag-task .taskgroup-container:not(.is-dragging) .title,
.drag-taskgroup .header-dropzone .taskgroup-container .project-bg,
.drag-taskgroup .taskgroup-container .title { pointer-events: none; }

.drag-taskgroup .header-dropzone .taskgroup-container,
.task-container.is-dragging .task { pointer-events: auto; }

.taskgroup > .extend,
.task .extend {
	position: absolute;
	top: 0;
	bottom: 0;
	width: var(--padding-hor);
	background: none;
	cursor: col-resize;
	z-index: 3;
}
.taskgroup > .extend {
	bottom: auto;
	height: calc(var(--font-size) * 1.6);
	display: none;
}

.is-extending { z-index: 10; }
.taskgroup .extend:first-child,
.task .extend:first-child { left: 0; }
.taskgroup .extend:last-child,
.task .extend:last-child { right: 0; }
.is-extending .extend,
.taskgroup:hover > .extend,
.task:hover .extend { 
	background: rgba(0,0,0,.3) url('img/icon-extend-handle.svg') no-repeat center center;
	background-size: 44%;
}

.touch-disabled .taskgroup-container,
.touch-disabled .task-container {
	pointer-events: none;
}
/*.touch-disabled .taskgroup-container .title,*/
.touch-disabled .trello-container:not(.invalid) .trelloboard {
	pointer-events: all;
}


.priority {
	float: left;
	display: block;
	width: .75rem;
	height: .75rem;
	margin: calc(var(--margin-hor)/2) 0 0 calc(var(--margin-hor)/2);
	border-radius: 1rem;
	box-shadow: none;
	z-index: 2;
}
.priority--2 { background-color: #3a94f4; background-color: #4473B5; }
.priority--1 { background-color: #78bcc4; }
.priority-0 { background-color: #999; }
.priority-1 { background-color: #edb337; }
.priority-2 { background-color: #ec3110; }

.priority--2:before,
.priority--2:after,
.priority--1:before,
.priority-1:before,
.priority-2:before,
.priority-2:after {
	display: block;
	content: "";
	width: 70%;
	height: 40%;
	position: absolute;
	left: 50%;
	margin-left: -35%;
	background-image: url('img/icon-arrow-up-small-white.svg');
	background-repeat: no-repeat;
	background-position: 50% center;
	background-size: 100%;
}
.priority--2:before,
.priority--2:after,
.priority--1:before {
	transform: rotate(180deg);
	background-position: 55% center;
}
.priority--1:before {
	top: 30%;
}
.priority-1:before {
	top: 25%;
}
.priority-0:before {
	content: "";
	width: 30%;
	height: 30%;
	border-radius: 50%;
	background-color: #fff;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -15%;
	margin-left: -15%;
}
.priority--2:before,
.priority-2:before {
	top: 15%;
}
.priority--2:after,
.priority-2:after {
	top: 40%;
}
.priority-edit .ui-popover-container {
	padding: .75rem;
	margin-top: -.75rem;
	left: 1.5rem;
}
.priority-edit .ui-popover-container form {
	padding-right: 0;
	width: 9rem !important;
}
.priority-edit label {
	display: block;
	margin-top: 0;
	width: 1.2rem;
	height: 1.2rem;
	line-height: 1.2rem;
	text-align: center;
	font-size: .8rem;
	color: transparent;
}
.priority-edit label:hover {
	opacity: .9;
}
.taskgroup.compacted .priority,
.taskgroup.compacted .priority-edit,
.priority-edit input {
	display: none;
}




/* resource-panel */
#touch-toggle,
#tag-panel,
#resource-panel,
#resource-panel .delete {
	position: fixed;
	top: 0;
	right: 0;
	width: 6rem;
	
	transition: right .5s ease-in-out;
	will-change: right;

	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
#tag-panel,
#resource-panel {
	bottom: 0;
	padding-top: 6rem;
	background-color: var(--color-bg-cellspacing);
	box-shadow: 0 0 2rem rgba(0,0,0,0.2);
}
#tablemain:before,
#resource-panel:after {
	content: "";
	position: absolute;
	left: 100%;
	top: 0;
	bottom: 0;
	width: 100px;
	background-color: #fff;
}
#tablemain:before {
	left: auto;
	right: 100%;
	position: fixed;
}

#resource-panel input {
	padding-left: calc(var(--padding-hor)/4);
	padding-right: calc(var(--padding-hor)/4);
	text-align: center;
	text-transform: uppercase;
}
#tag-panel input::-webkit-input-placeholder {
	text-transform: none;
}
#tag-panel input::-moz-placeholder {
	text-transform: none;
}
#resource-panel input::-webkit-input-placeholder {
	text-transform: none;
}
#resource-panel input::-moz-placeholder {
	text-transform: none;
}
.resource,
.task,
.tag {
	text-align: center;
	text-transform: uppercase;
	line-height: calc(var(--padding-ver)*1.75);
	cursor: grab;
}
.taskgroup {
	text-align: left;
	line-height: calc(var(--padding-ver)*1.75);
}
.scroll-area {
	overflow-y: auto;
	position: static;
}
#tag-panel .scroll-area,
#resource-panel .scroll-area {
	max-height: calc(100vh - 6rem);
	padding-bottom: 1rem;
}
#resource-panel .scroll-area {
	max-height: calc(100vh - 12rem);
}
#resource-panel .scroll-area > div,
#resource-panel .scroll-area > form {
	width: 4rem;
	margin: 0 auto;
}
th .scroll-overflow-ui-container,
#tag-panel .scroll-area > .tag-container,
#resource-panel .scroll-area > .resource {
	margin-top: calc(var(--margin-hor)/2);
}
th .scroll-overflow-ui-container,
#tag-panel .scroll-overflow-ui-container,
#resource-panel .scroll-overflow-ui-container {
	position: absolute;
	width: 100%;
}
.touch-disabled .scroll-overflow-ui-container,
.invisible {
	visibility: hidden;
}

.presentation-state #resource-panel,
.presentation-state #resource-panel .delete,
.presentation-state #touch-toggle,
.touch-disabled #resource-panel,
.touch-disabled #resource-panel .delete  {
	right: -8rem;
}
.touch-disabled #resource-panel form {
	display: none;
}

th .scroll-overflow-ui-container {
	position: absolute;
	top: -1.675rem !important;
	left: 0;
	right: 0;
	padding: 0;
	margin: 0;
	overflow: visible;
	height: 5rem;
}
th .scroll-overflow-ui-container .ui-popover-container form,
.taskgroup-container .ui-popover-container form {
	width: 10em;
}


.taskgroup-container.is-focus { z-index: 10; }

.trello-container {
	position: absolute;
	right: 0;
	top: 0;
}
.taskgroup-container .trello-container {
	position: relative;
	float: right;
	z-index: 2;
}


button.trelloboard {
	background: transparent url('img/icon-trello-blue.svg') no-repeat center center;
	background-size: 1rem;
	width: 2rem;
	height: 1.65rem;
}
button.trelloboard:hover,
button.trelloboard:active {
	margin: 0 !important;
	border: none !important;
}
.trello-container.invalid .trelloboard {
	background-image: url('img/icon-trello-grey.svg');
}
.trello-container.external-link .trelloboard {
	background-image: url('img/icon-externallink-blue.svg');
}
.trello-container.trelloboard-link .trelloboard {
	background-image: url('img/icon-trello-blue.svg');
}
.trello-container.trellocard-link .trelloboard {
	background-image: url('img/icon-trello-card-blue.svg');
}
.presentation-state .trello-container,
.touch-disabled .trello-container.invalid,
.taskgroup.compacted .trello-container {
	display: none;
}




/* tags */

.tags-container {
	display: block;
}
.tag-container {
	display: inline-block;
}
.tag-container.is-dragging {
	opacity: .2 !important;
}

.tag {
	padding-right: 1.5em;
	background: var(--color-bg-cellspacing) url('img/icon-tag-white.svg') no-repeat right .4em center;
	background-size: .75em;
}


table .tag-container {
	margin-right: calc(var(--padding-hor)/4);
}
thead .filter-state > span,
thead .filter-state > button,
thead .filter-state > .resource,
table .tag {
	display: inline-block;
	
	text-transform: uppercase;
	line-height: 1.5;
}
table .tag {
	padding-left: calc(var(--padding-hor)/4);
	margin: 0;
	
	transition: background-color .3s;
	will-change: background-color, padding, color, background-image;
}


thead .filter-state {
	position: absolute;
	left: var(--padding-hor);
	right: var(--padding-hor);
	margin-right: 1px;
	text-align: right;
	overflow: hidden;
	text-overflow: ellipsis;
}
thead .filter-state > span {
	color: var(--color-txt-grey);
}
thead .filter-state > .resource {
	padding-left: .25rem;
	padding-right: .25rem;
}
thead .filter-state > .tag-container,
thead .filter-state > .resource {
	margin-left: .125rem;
	margin-right: .125rem;
}
thead .filter-state .tag,
thead .filter-state .resource,
.touch-disabled #resource-panel .resource {
	cursor: pointer;
}

thead .filter-state > .tag-container:last-child,
thead .filter-state > .resource:last-child {
	margin-right: 0;
}

thead .filter-state .clearall {
	overflow: hidden;
	text-indent: -999rem;
	width: 1rem;
	height: 1rem;
	padding: 0;
	margin-right: .5rem;
	background: transparent url('img/icon-close-grey.svg') no-repeat center center;
	background-size: .5rem;
}
thead .filter-state .clearall:hover {
	background-image: url('img/icon-close-blue.svg');
}
thead .filter-state .tag:hover {
	background-image: url('img/icon-close-white.svg');
}



.toggle-tags {
	display: inline-block;
	background: var(--color-bg-cellspacing) url('img/icon-tags-blue.svg') no-repeat center center;
	background-size: contain;
	padding: 0;
	margin: 0;
	width: 2rem;
	height: 2rem;
	overflow: hidden;
	text-indent: -999rem;
	border: solid calc(var(--padding-hor)/2) transparent;
	vertical-align: bottom;
	float: right;
}
.toggle-tags:hover {
	background-color: var(--color-btn-bg-white-hover);
}
button.toggle-tags:active {
	border-bottom: calc(var(--padding-hor)/2) transparent;
	border-top: calc(var(--padding-hor)/2) transparent;
}
.toggle-tags,
button.toggle-tags:active {
	margin-top: .5rem;	
}
.toggle-tags.showing-tag-menu {
	background-image: url('img/icon-tags-white.svg');
	background-color: var(--color-link-hover);
}

#tag-panel {
	right: none;
	left: -15rem;
	top: calc(var(--padding-ver)/2);
	min-width: 14rem;
	
	transition: left .3s ease-in-out;
	will-change: left;
}
.presentation-state #tag-panel {
	left: -15rem !important;
}
#tag-panel .tag {
	text-transform: none;
	text-align: left;
	padding-left: .5rem;
	
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}


#tag-panel .tag.is-filtered,
#resource-panel .resource.is-filtered {
	opacity: .25;
}
table .tag.is-filtered {
	background-color: var(--color-txt-lightgrey) !important;
}
.touch-disabled tbody .tag,
.presentation-state tbody .tag {
	background-image: url('img/icon-tag-grey.svg');
	background-color: transparent !important;
	background: none !important;
	color: var(--color-txt-grey) !important;
	padding-left: 0;
	padding-right: .75em;
}
.touch-disabled tbody .tag.is-filtered,
.presentation-state tbody .tag.is-filtered,
.touch-disabled tbody .resource.is-filtered,
.presentation-state tbody .resource.is-filtered {
	opacity: .25;
}
#tag-panel .scroll-area {
	padding-bottom: calc(var(--margin-ver)*2);
	padding-left: var(--margin-hor);
	padding-right: var(--margin-hor);
}
#tag-panel .scroll-area > * {
	display: block;
	margin: 0 auto;
}




#touch-toggle {
	top: auto;
	bottom: 0;
	height: 6rem;
	
	overflow: hidden;
	text-indent: -999em;
	
	z-index: 10000;
	
	background: transparent url('img/icon-disabletouch-grey.svg') no-repeat center center;
	background-position: center center;
	background-size: 3rem;
}
#touch-toggle:hover {
	background-image: url('img/icon-disabletouch-blue.svg');
}
.touch-disabled #touch-toggle {
	background-image: url('img/icon-disabletouch-blue.svg');
}
.touch-disabled #touch-toggle {
	right: 0;
}


.hide-in-presentation {
	opacity: 1;
	transition: opacity .5s;
	will-change: opacity;
}
.touch-disabled .hide-touch-disabled,
.touch-disabled .hide-in-presentation:not(.show-touch-disabled),
.presentation-state .hide-in-presentation {
	opacity: 0;
}







.android main > div {
	overflow: hidden;
	overflow-x: scroll;
	width: var(--screenwidth);
}
/*
.android #resource-panel .delete {
	right: auto;
	left: var(--screenwidth);

	transition: left .5s ease-in-out;
	will-change: left;
}
.android .presentation-state #resource-panel,
.android .presentation-state #resource-panel .delete {
	right: auto;
	left: calc(var(--screenwidth) + 8rem);
}
*/


/* delete well */
.delete {
	display: block;
	background: var(--color-bg-cellspacing) url('img/icon-delete.svg') no-repeat center center;
	background-size: 50%;
	height: 6rem;
	overflow: hidden;
	text-indent: -999rem;
	border: solid calc(var(--padding-hor)/2) transparent;
}
.delete.hover {
	border-color: var(--color-border-cellselected);
}